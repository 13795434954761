.card1 {
  height: auto;
  border-radius: 10px;
  background: #dfdfdf50;
  gap: 16px;
  padding: 32px;
}

.section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 64px;
}

.section21 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section21 a {
  text-decoration: none;
  color: #5a5a5a;
  border-radius: 24px;
  background: rgba(250, 250, 250, 0.98);
  padding: 8px 16px;
}

.section22 {
  display: flex;
  height: fit-content;
  gap: 12px;
}

.section23 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.card0 {
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 16px;
}

header {
  color: #404040;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

header strong {
  font-weight: 900;
}

.filter a {
  text-decoration: none;
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  border-radius: 24px;
  background: rgba(250, 250, 250, 0.98);
  color: #5a5a5a;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: fit-content;
}

.active {
  background-color: #006838 !important;
}
