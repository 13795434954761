nav {
  background: #006838;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
}

.left-nav {
  align-items: center;
  display: flex;
  gap: 64px;
}

.left-nav a {
  width: 120px;
  height: auto;
}

.search-input input {
  background: none;
  border: 0;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
}

.search-input {
  display: flex;
  flex-direction: row;
  border-radius: 4.84px;
  border: 1.452px solid #dbdbdb;
  background: #fafafa;
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
}

.search {
  padding-left: 4px;
  padding-right: 4px;
}

form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

form .filter {
  display: flex;
  width: 42.594px;
  height: 42.594px;
  padding: 9.922px;
  justify-content: center;
  align-items: center;
  border-radius: 4.134px;
  border: 1.452px solid #dbdbdb;
  background: #fafafa;
  text-align: center;
}

.right-nav ul {
  display: flex;
  gap: 20px;
  margin: 0;
  align-items: center;
}

.right-nav ul a {
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 16.724px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.right-nav ul a li {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
}

.icon {
  height: auto !important;
    width: 16px !important;
}
