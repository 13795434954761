.cattle {
  width: 100%;
  height: 184.351px;
  background-image: url(../images/cattle.svg);
  border-radius: 5px;
}

.top-tag {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 18px;
  margin-left: 16px;
}

.top-tag div {
  padding: 4px 8px;
  border-radius: 3px;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.619px;
  text-transform: uppercase;
}
.description {
  width: 161px;
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%; /* 10.88px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.bb {
  z-index: 2;
  position: absolute;
  margin-top: -62px;
  width: 110px;
  height: auto;
  margin-left: 230px;
}

.price {
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.price span {
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.card {
  width: 375px;
}



.information {
  border-radius: 12px;
  z-index: 1;
  margin-top: -15px;
  background-color: white;
  padding: 12px;
  width: 100%;
  height: fit-content;
}

.discount div {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  font-size: 12px;
}

.left-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.right-card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
