h1,h2,h3,h4,h5,h6{
  font-family: "Clash Display", sans-serif !important;
}
.n-right-div {
  border-radius: 10px;
  border: 0.5px solid #000;
  background: var(--color-primary-60, #fff);
  width: 524px;
  height: 570px;
  flex-shrink: 0;
}

.n-rd-head h3{
  color: #000;
font-size: 24.178px;
font-style: normal;
font-weight: 700;
line-height: 200%; /* 48.356px */

}

.n-rd-head{
  border-bottom: 1px solid #D9D9D9;
}

.n-body-child{
  border-bottom: 1px solid #D9D9D9;
}

.n-body-text{
  color: #000;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 27px */
}