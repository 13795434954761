.wlc-main {
  width: 144px;
  height: 100% ;
  display: flex;
  flex-direction: column;
}

.wlc-img {
  width: 100%;
  border-radius: 5px;
  opacity: 0.99;
}

.wlc-h3 {
  color: #000;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 132%; /* 15.84px */
}

.wlc-h4{
  color: var(--color-primary-60, #FFF);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.wlc-verify{
  display: flex;
padding: 2px 4px;
justify-content: space-between;
align-items: center;
border-radius: var(--cart-cart, 0px);
background: #0068C8;
}
