.delivery-div1 {
  background: #d9d9d9a0;
  display: flex;
  flex-direction: row;
  padding: 24px 124px;
  justify-content: space-between;
  margin-top: 48px;
}

.delivery-button-active {
  width: 366px;
  height: 129px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #d9d9d989;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-button-active button {
  background: #006300;
  display: flex;
  width: 303px;
  padding: 13px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.delivery-button {
  width: 366px;
  height: 129px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1e1e1e;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-div2 {
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.delivery-div2-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 76px;
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.delivery-div3 {
  display: flex;
  flex-direction: column;
  padding: 48px 0px;
  border-radius: 10px;
  background: #006838;
  height: fit-content;
  gap: 16px;
}

.delivery-div3 div {
  background: rgba(255, 255, 255, 0.066);
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px 48px;
}

.delivery-div3 div h4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.delivery-name {
  color: #fff;
  font-family: Clash Display Variable;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-address {
  color: #fff;
  font-family: Clash Display Variable;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-number {
  color: #fff;
  font-family: Clash Display Variable;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-div4 {
  display: flex;
  flex-direction: column;
  padding: 48px 48px;
  border-radius: 10px;
  background: #c4c4c490;
  height: fit-content;
  gap: 16px;
}

.delivery-div04 {
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.delivery-div04-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 76px;
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.delivery-div41 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.delivery-div41 input {
  width: 32.664px;
  height: 32.664px;
  border: 2px solid #006300;
}

.delivery-div41 h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  color: #000;
  font-family: "Dm Sans", sans-serif;
  font-size: 26.131px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 31.357px */
}

.delivery-div40 h2 {
  color: #1e1e1e;
  font-family: "Dm Sans", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.delivery-div40 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.delivery-div5 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 64px;
}
.delivery-div5 button {
  display: flex;
  width: 711px;
  height: 93px;
  padding: 16px 44.488px;
  justify-content: center;
  align-items: center;
  border-radius: 11.122px;
  background: #006838;
  color: #fff;
  font-family: "Dm Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.delivery2-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 48px;
}

.delivery2-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.delivery2-details div table tr {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 0%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.delivery2-details div table {
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: space-between;
}

.delivery2-div2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 76px;
  color: #5a5a5a;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.delivery2-details div {
  border-radius: 10px;
  background: #006838;
  padding: 32px;
}

.delivery2-total {
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
