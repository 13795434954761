.wn-btn {
  border-radius: var(--cart-cart, 0px);
  background: #006838;
  width: 260px;
  height: 64px;
  color: #ffffff;
  text-align: center;
  font-size: 19.875px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.wnav {
  /* background: #fff; */
  border-radius: var(--cart-cart, 0px);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.webnav-list {
  color: #000;
  text-align: center;
  font-size: 19.875px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}

.wl-link{
  text-decoration: none;
  color: inherit;
}
