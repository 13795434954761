.web-text {
  color: var(--color-primary-60, #fff);
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.webfooter-divider{
  border-radius: var(--cart-cart, 0px);
background: #FFF;
width: 100%;
height: var(--cart-cart, 1px);
}

.social{
  text-decoration: none;
  color: #fff;
}