.fp-main-div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.fp-form{
gap: 24px;
}
