.p-pic {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  overflow: hidden;
}

.p-pic-lg {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.p-div4 {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.p-div4 h3 {
  color: #000;
  font-size: 24.178px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%; /* 48.356px */
}

/* Profile settings container */
.profileSettings {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #000;
  font-size: 24.178px;
  font-style: normal;
  font-weight: 400;
  line-height: 250%; /* 60.444px */
}

.profileHead {
  font-family: "Clash Display", sans-serif;
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.p-logout {
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}


.profileSetting-div1 div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.toggle-dropdown {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 16px;
}

.dropDownMenu li a {
  text-decoration: none;
  color: #000;
  font-size: 24.178px;
  font-style: normal;
  font-weight: 400;
  line-height: 250%; /* 60.444px */
}

.ps-div-icon {
  height: 32px !important;
  width: 32px !important;
}

.pdr-table tr td {
  color: #000;
  font-size: 24.178px;
  font-style: normal;
  font-weight: 400;
  line-height: 250%; /* 60.444px */
}

.ps-security {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  padding-right: 32px;
}

.security-dropdown {
  position: absolute;
  top: 100%;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ps-maindiv{
  width: 365px;
}

.sp-head{
  color: #000;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: normal;
}