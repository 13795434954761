.website {
  height: 100vh;
}

.website-hero {
  width: 100%;
  height: fit-content;
  justify-content: center;
  /* z-index: 1; */
}

.website-hld h2 {
  color: #000;
  font-family: Clash Display;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: auto;
}

.website-hld h2 span {
  font-weight: 700;
}

.website-hld h4 {
  color: #000;
  font-family: Clash Display;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: auto;
}

.website-rh-bg {
  background-image: url(../images/New-hero-bg.svg);
  background-repeat: no-repeat;
}

.wrhi-shadow {
  width: 620px;
  height: 96px;
  border-radius: 620px;
  background: #000;
  filter: blur(75px);
}

.website-rh-img {
  height: 475px;
  width: 370px;
  z-index: 2;
  margin-left: 55px !important;
}

/* .websitebgdrop {
  width: 624px;
  height: auto;
  
  margin-top: 400px;
  margin-left: -259px;
  z-index: -1;
  top: 0;
  left: 0;
} */

.website-rh-img-mobile{
  width: 400px !important;
  max-width: 500px;
}

/* section 2 */
.s2-ld-bg {
  background-image: url(../images/s2-ld-bg.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.website-s2-about-p {
  color: #4d4d4d;
  font-family: Clash Display;
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.website-s2-about-h4 {
  color: #4d4d4d;
  font-family: Clash Display;
  /* font-size: 40px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.s2-rd-card {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  width: 643px;
  height: 112px;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(30, 198, 61, 0.3) 0%,
    rgba(30, 198, 61, 0) 100%
  );
}

.section2-card {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  /* width: 932px; */
  height: auto;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(30, 198, 61, 0.3) 0%,
    rgba(30, 198, 61, 0) 100%
  );
}

/* .website-s2-card-icon {
  width: 80px;
  height: auto;
} */

/* section 3 */
.website-s3 h5 {
  color: var(--color-primary-60, #fff);
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.website-s3 p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Clash Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ws3-card {
  border-radius: var(--cart-cart, 0px);
  background: rgba(255, 255, 255, 0.15);
  width: 345px;
  height: 260px;
}

.ws3-icon {
  width: 80px;
  height: 80px;
  opacity: 0.6;
}

.website-menu-icon {
  height: 40px;
  width: 53px;
}
.s4-c-text h5 {
  color: #4d4d4d;
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.s4-c-text h2 {
  color: #000;
  font-family: Clash Display;
  /* font-size: 40px; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* section 4 */
.website-s4 {
  border-radius: var(--cart-cart, 0px);
  background: linear-gradient(180deg, rgba(226, 255, 231, 0) 0%, #e2ffe7 100%);
  width: 100%;
  height: 100%;
}

.s4-bg1 {
  border-radius: var(--cart-cart, 100%);
  background: #3db54a;
}

.s4-bg2 {
  border-radius: var(--cart-cart, 100%);
  background: #29ea3e;
}

.s4-bg3 {
  border-radius: var(--cart-cart, 100%);
  background: linear-gradient(180deg, #006838, rgba(0, 104, 56, 0) 100%);
}

/* section 5 */
.website-s5 {
  background-image: url(../images/website-s5-img.svg);
  /* width: 1335px; */
  /* height: 293px; */
  border-radius: 30px;
}

/* section 6 */

.website-s6-dropdown {
  border-bottom: 1px solid #000;
}

.website-form-faq div input,
textarea {
  display: flex;
  /* width: 538px; */
  align-items: center;
  border-radius: var(--cart-cart, 0px);
  border: 1px solid rgba(77, 77, 77, 0.4);
}
.website-form-faq div {
  margin-bottom: 16px;
}

/* section 7 */
.website-s7 {
  background-image: url(../images/website-s7-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1654px;
  width: 100%;
}
/* mobile */
.website-s7-mobile {
  background-image: url(../images/website-s7-mobile-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  width: 100%;
}

.website-s7-div2-icon {
  height: 32px !important;
  width: 32px !important;
}

/* section 8 */

.website-s8-bg {
  border-radius: var(--cart-cart, 0px);
  background: radial-gradient(
    317.01% 122.02% at 5.23% 10.71%,
    #006838 0%,
    #48a928 23.96%,
    #006300 50.52%,
    #006300 50.53%,
    #48a928 73.96%,
    rgba(0, 104, 56, 0.99) 100%
  );
  /* width: 100%;
  height: 960px; */
}

.website-s8-pic {
  /* width: 403px;
  height: 403px; */
  flex-shrink: 0;
  border-radius: var(--cart-cart, 403px);
  border: 12px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
}

/* section 9 */
.website-s9-div {
  border-radius: var(--cart-cart, 0px);
  background: linear-gradient(
    276deg,
    #f25c05 0%,
    rgba(242, 133, 5, 0.85) 53.65%,
    #f25c05 100%
  );
  display: inline-flex;
  /* padding: 64px; */
  flex-direction: column;
  align-items: center;
  gap: var(--cart-cart, 43px);
}

.website-newsletter-btn {
  display: flex;
  width: 200px;
  height: 52px;
  padding: var(--cart-cart, 0px);
  justify-content: center;
  align-items: center;
  gap: 14.575px;
  border-radius: var(--cart-cart, 0px);
  border: 2px solid var(--color-primary-60, #fff);
  background: #ffc700;
}

.website-newsletter-input {
  display: flex;
  /* width: 538px; */
  padding: var(--cart-cart, 13px);
  align-items: center;
  gap: var(--cart-cart, 0px);
  border-radius: var(--cart-cart, 0px);
  background: var(--color-primary-60, #fff);
}
