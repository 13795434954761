.ap-description {
  width: 100%;
  height: 100px;
  border-radius: var(--Cart-cart, 0px);
  background-color: rgba(0, 104, 56, 0.2);
}

.faddpbtn {
  width: 40.919px;
  height: 39.296px;
  border-radius: 83.324px;
  border: 1.666px dashed #3eaa4a;
}

.addproductcardbg {
  width: 172.955px;
  height: 214px;
  background-image: url(../images/addproductbg.svg);
}

.addproductdiv {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(64, 64, 64, 0.1);
}

.adddetailsbg {
  border-radius: 6.764px;
  background: rgba(217, 217, 217, 0.3);
}

.add-details-input {
  border-radius: 9.494px;
  border: 0.949px solid #000;
  background: #f4f4f4;
}
