table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  /* border: 1px solid #ddd; */
  padding: 24px;
  text-align: left;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

table th {
  background-color: #ededed;
  text-align: left;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

th:first-child {
  width: 40px;
}

th:nth-child(2) {
  width: 300px;
}

th:last-child {
  width: 24px;
}

/* pagination */
.mp-pagination {
  color: #3d3d3d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-top: 1px solid #EAECF0;
}

.mp-pagination button{
  border-radius: 8px;
border: 1px solid #E7E7E7;
background: #FFF;

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
display: flex;
padding: 8px 14px;
justify-content: center;
align-items: center;
gap: 8px;
}
