*{
  font-family: 'DM Sans', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
}
footer{
  background-image: url(../images/footer_img.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
}

footer .container{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap ;
}

.left-div{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 24px;
    flex-wrap: wrap ;
    gap: 16px;
}

.left-div p{
  color: #FFF;
  font-family: 'DM Sans', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.left-div form{
  flex-wrap: wrap;
}

.left-div form input{
  width: 316px;
height: 48px;
flex-shrink: 0;
border-radius: 5px;
background: #D9D9D9;
}

.left-div form button{
  width: 85px;
height: 48px;
flex-shrink: 0;
border-radius: 5px;
border: 2px solid #D9D9D9;
color: #D9D9D9;
font-family: 'DM Sans', sans-serif;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
}

.right-div{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 24px;
  margin-top: 0 !important;
  flex-wrap: wrap ;
}

.right-div label{
  color: #FFF;
  font-family: 'DM Sans', sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 16px;
}

.right-div h1{
  color: #FFF;
  font-family: 'DM Sans', sans-serif;
font-size: 15.672px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 1.567px;
text-transform: uppercase;
}

.right-div ul a{text-decoration: none;}

.right-div ul a li{
  color: #FFF;
  font-family: 'DM Sans', sans-serif;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1px;
text-transform: uppercase;
text-decoration: none;
}

.links{
  gap: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap ;
}

.links div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.links div ul{
  align-items: flex-start;
  padding: 0px !important;
  margin: 0px !important;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconify{
  height: 24px;
  width: 24px;
}