.main-div {
  min-height: 100vh;
}

.logo {
  width: 105px;
  height: auto;
}

.createAccount-btn2 {
  width: 400px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 14.037px;
  border: 1.404px solid rgba(0, 0, 0, 0.5);
  color: #1b1b1b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.createAccount-btn2 strong {
  color: #1b1b1b;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cta {
  gap: 8px;
}

.div-up {
  gap: 12px;
}

.div {
  gap: 16px;
  margin-top: 40px;
}

.drop-img {
  width: 300px;
  height: auto;
  z-index: 1;
  position: absolute;
  margin-left: 1100px;
  margin-top: -200px;
}

.createAccount-member {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex !important;
  align-items: center !important;
}

.createAccount-member a {
  color: #006838;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
