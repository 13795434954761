.fd-account{
  width: 238px;
height: fit-content;
border-radius: 10px;
border: 1px solid rgba(32, 32, 32, 0.5);

}

.fd-leftdiv-icon{
  width: 29px;
height: 29px;
color: #000000;
}

/* farmers dashboard divider */

.fd-divider{
  width: 2px;
height: 932px;
border-radius:0px;
background: #D9D9D9;
}

.fd-rightdiv-second-div-bg{
  width: 100%;
height: 100%;
border-radius: 10px;
border: 1px solid rgba(0, 0, 0, 0.1);
background: rgba(64, 64, 64, 0.1);

}