* {
  font-family: "Clash Display", sans-serif !important;
}
.login-div1 {
  min-height: 100vh;
  background-image: url(../images/signup-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 20% !important;
}

header {
  color: #000;
  font-family: "Clash Display", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

header strong {
  color: #006838;
  font-family: "Clash Display", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-header {
  font-family: "Clash Display", sans-serif#000;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-div1 img {
  height: 100vh;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
}

.login-div2 {
  display: flex;
  align-items: center;
  justify-content: center;

}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.inputs label {
  color: #000;
  font-family: "Clash Display", sans-serif ;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputs input {
  border-radius: 14.037px;
  border: 1.404px solid rgba(0, 0, 0, 0.5);
  width: 400px;
  height: 60px;
}

.login-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-div20 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.login-form form .inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-div4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-div4 button {
  width: 400px;
  height: 74px;
  background-color: #006838;
  border-radius: 14.037px;
  border: 1.404px solid rgba(233, 214, 214, 0.5);
  color: #fff;
}

.login-div4 div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.forgot-password {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
