.rp-main-div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rp-form {
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rp-form .inputs{
  width: 100% !important;
}

.rp-form .inputs input{
  width: 100% !important;
}

.rp-btn{
  width: 100% !important;
}

.rp-btn button{
  width: 100% !important;
}


.vc-otp {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* flex-wrap: nowrap; */
  gap: 24px;
}

.vc-button{
  width:100% !important;
}

.vc-otp input {
  border-radius: 14.037px;
  border: 1.404px solid rgba(0, 0, 0, 1);
  width: 72px !important;
  height: 72px;
}

.vc-form{
  gap: 16px;
}

.vc-div3{
  align-items: flex-start !important;
}